<template>
  <app-modal
    :title="track ? 'Edit your track' : ' Create a new track'"
    @submit="onSubmit"
  >
    <app-form-input-text
      v-model="form.title"
      data-cy-title
      label="Title"
      required
    />
  </app-modal>
</template>

<script lang="ts" setup>
import { slugify } from "~/shared/url/url.utils";

const properties = defineProps<{
  organizationId: DatabaseTable<"organizations">["id"];
  track?: DatabaseTable<"organization_tracks">;
}>();

const { close } = useModal("organizationTrackEditionModal");
const form = reactive<DatabaseInsertRequest<"organization_tracks">>({
  organization_id: properties.organizationId,
  title: properties.track?.title ?? "",
  slug: properties.track?.slug ?? "",
});

watch(
  () => form.title,
  (title) => {
    form.slug = slugify(title);
  },
);

function onSubmit() {
  return close({ payload: form, confirmed: true });
}
</script>
